<template>
  <div class="content">
    <div class="__filter_chain__header___">
      <div class="__titleS">
        <span>FILTER-CHAIN</span>
      </div>
      <i 
        v-if="chainResetable"
        aria-hidden="true" 
        class="v-icon v-icon--link mdi mdi-close theme--light" 
        title="Clear all filters"
        @click="Clear"
      />
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.AREA"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Area"
        item-text="AREA"
        item-value="AREA"
        placeholder="Select a AREA"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.AREA"
        @input="() => { onFilterChanged('AREA') }"
      ></v-select>     
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.ZONE"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Zone"
        item-text="ZONE"
        item-value="ZONE"
        placeholder="Select a ZONE"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.ZONE"
        @input="() => { onFilterChanged('ZONE') }"
      ></v-select>       
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.LEVEL"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Level"
        item-text="LEVEL"
        item-value="LEVEL"
        placeholder="Select a LEVEL"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.LEVEL"
        @input="() => { onFilterChanged('LEVEL') }"
      ></v-select>       
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.BLK"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Block"
        item-text="BLK"
        item-value="BLK"
        placeholder="Select a BLK"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.BLK"
        @input="() => { onFilterChanged('BLK') }"
      ></v-select>       
    </div>
    <div class="clear_button">
      <!-- <j-button class="type01 clear type_full"  @click="Clear">Clear</j-button>  -->
    </div>               
  </div>
</template>
<script>
import '@/assets/stylus/ui/component/_j.lnb.report.category_.styl'
import _ from 'lodash'
import __C from '@/primitives/_constant_'
import __M from 'moment'
import { mapState,mapGetters, mapActions } from 'vuex'
import StaticOptions from '@/mixins/staticOptions'
import FileServiceMixin from '@/mixins/file.service.mixin'
import { FileService, DBSupportService } from '@/services'
export default {
  name: 'j-lnb-Progress-Block',
  mixins: [
    FileServiceMixin,
    StaticOptions
  ],
  props: {
    value: null
  },
  data: () => ({
    catcode: '',
    recentSelected: 0,
    fsources: {},
    fotions: {},
    fnames: ['AREA', 'ZONE', 'LEVEL', 'BLK'],
    selectedItem: {},
    fileService: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['getStatusUp']),
    __C_() { return __C },
    chainResetable() {
      if (Object.keys(this.selectedItem).length > 0) return true
      else return false
    }
  },
  created() {
    this.fileService = new FileService()
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    // this.fileService.getFilterOptionsList().then(res => {
    //   Object.keys(res).forEach(k => {
    //     this.fsources[k] = [...res[k]]
    //     this.fotions[k] = [...res[k]]
    //   })
    //   this.fotions = { ...this.fotions }
    // })
    
    this.queryLibService.getSqlQueryResult({
      idx: 0, 
      name: 'ProgressPhotoOptions',
      filters: ''
    }).then(res => {
      if(!res) return
      Object.keys(res[0]).forEach(k => {
        this.fsources[k] = res
        this.fotions[k] = res
      })
      this.fotions = { ...this.fotions }
    })

  },
  watch: {
    getStatusUp(v) {
      if (v) {
        this.selectedItem = {}
        this.selectedItem = { ...this.selectedItem }
        this.PhotoStatusSet(false)
        // this.fileService.getFilterOptionsList().then(res => {
        //   Object.keys(res).forEach(k => {
        //     this.fsources[k] = [...res[k]]
        //     this.fotions[k] = [...res[k]]
        //   })
        // })

        this.queryLibService.getSqlQueryResult({
          idx: 0, 
          name: 'ProgressPhotoOptions',
          filters: ''
        }).then(res => {
          if(!res) return
          Object.keys(res[0]).forEach(k => {
            this.fsources[k] = res
            this.fotions[k] = res
          })
          this.fotions = { ...this.fotions }
        })

        this.setItem()
      }
    }
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'PhotoItemSet',
      'PhotoStatusSet',
      'PhotoClearSet'
    ]), 
    setItem() {
      let res = this.selectedItem
      this.PhotoItemSet(_.cloneDeep(res))
    },
    Clear() {
      this.selectedItem = {}
      this.selectedItem = { ...this.selectedItem }
      this.fotions = { ... this.fsources}
      this.setItem()
      this.PhotoClearSet(true)
    },
    onFilterChanged(name) {
      this.PhotoClearSet(false)
      let idx = this.fnames.findIndex(n => n == name)
      
      let parentnames = this.fnames.slice(0, idx+1)
      let childnames = this.fnames.slice(idx+1)

      if(idx+1 < this.fnames.length) {
        childnames.forEach(name__ => {
          this.fotions[name__] = this.fsources[name__].filter(s => {
            let wheresource = ''
            let whereselect = ''

            parentnames.forEach(n__ => {
              if(this.selectedItem[n__]) {
                if(this.selectedItem[n__] == 'General') {
                  // skip ---
                } else {
                  wheresource += `${s[n__]}`
                  whereselect += `${this.selectedItem[n__]}`
                }
              }
            })
            
            this.selectedItem[name__] = null
            this.selectedItem = { ...this.selectedItem }

            return s[name__] == 'General' || wheresource == whereselect
          })
        })

      }
      this.setItem()
    },
  }
}
</script>
<style lang="stylus" scoped>
  .__titleS
    margin-left : 1.4rem;
    line-height : 1.1rem;
    color :#fff;
    font-size: 1rem;
    font-font-weight :600;
</style>
